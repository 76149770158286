import React, { useEffect } from "react";
import { isUserLoggedIn } from "../../tools/utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { merchantAxiosInstance } from "../../network/api";
import { MERCHANT_SERVER } from "../../network/config";
import { toast } from "react-toastify";
import PaystackPop from "@paystack/inline-js";

import "./index.scss";

const VerifyPay = ({ msg }) => {
  const navigate = useNavigate();


  const [searchParams] = useSearchParams();
  const access_code = searchParams.get("access_code");
  const reference = searchParams.get("reference");
  const merchantId = searchParams.get("merchantId");
  const paymentId = searchParams.get("paymentId");
  const { verifyPayment, cancelPayment } =
    MERCHANT_SERVER.SUBSCRIPTION_CONTROLLER;
  

  const goBack = () => {
    setTimeout(() => {
      navigate("/AfiariMerchantForm");
    }, 3000);
  };


  const verifyPayments = async (transaction_ref) => {
    try {
      const res = await merchantAxiosInstance.post(
        `${verifyPayment.route}?reference=${transaction_ref}&payment_method=${paymentId}`
      );

      const { status, error_message } = res;

      if (!status) {
        toast.error(error_message);
        goBack();
        return;
      }
      return status;
    } catch (err) {
      toast.error(
        "Couldn't verify subscription payment . Something went wrong"
      );
      console.log(err);
    }
  };

  const cancelTransaction = async (merchantId, payref) => {
    try {
      const route = `${cancelPayment.route}?merchantId=${merchantId}&reference=${payref}`;
      const res = await merchantAxiosInstance.post(route);

      const { status, error_message } = res?.data;

      if (!status) {
        toast.error(error_message);
        return;
      }

      toast.info("You cancelled the payment for subscription");
      return;
    } catch (error) {
      console.log(error);
    }

    return;
  };

  const handleExecution = async () => {

    if(access_code){
      const paystack = new PaystackPop();

      paystack.resumeTransaction(access_code, {
        onSuccess : async (transaction) =>{
          const res = await verifyPayments(transaction.reference);
          if(res){
            navigate("/AfiariMerchantForm/successful");
          }
        },
        onCancel : async ()=>{
          console.log("CANCELLED")
          await cancelTransaction(merchantId, reference);
          goBack();
        }
      })
    }
  };

  useEffect(() => {
    if (!isUserLoggedIn()) {
      navigate("/");
    }
    handleExecution();
  }, []);


  return (
    <main className="successful">
      <div className="successful_wrapper">
        <div className="successful_wrapper_heading">
          <h2>Subscription Payment Verification</h2>
          <div className="spinner"></div>
          {/* <figure className="successful_wrapper_heading_pic">
            <img src="/assets/images/partying-face.png" alt="party-face" />
          </figure> */}
        </div>
        <p className="successful_wrapper_p">{msg}</p>
      </div>
      <figure className="successful_partycap">
        <img src="/assets/images/party-popper.png" alt="party-cap" />
      </figure>
      <figure className="successful_partyface">
        <img src="/assets/images/party-face.png" alt="party-face" />
      </figure>
    </main>
  );
};

export default VerifyPay;
