import React, { useState, useEffect } from "react";
import FormWrapper from "../../pages/AfiariMerchant/FormWrapper";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { merchantAxiosInstance } from "../../network/api";
import { MERCHANT_SERVER } from "../../network/config";
import {
  getAvailablePaymentProcessorsLocalStorage,
  setAvailablePaymentProcessorsToLocalStorage,
} from "../../tools/utils";

const BusinessForm = ({ BusinessData, updateFields }) => {
  const { Name, PhoneNumber, Email, Description, subscriptionPlanId } =
    BusinessData;

  const navigate = useNavigate();

  const { getPaymentOptions } = MERCHANT_SERVER.LOOKUP_CONTROLLER;
  const { submitBusinessRegForm, getMerchant } =
    MERCHANT_SERVER.MERCHANT_CONTROLLER;
  const { getSubscriptionPlan, buyPlan } =
    MERCHANT_SERVER.SUBSCRIPTION_CONTROLLER;
  const { getPaystackPublickey } = MERCHANT_SERVER.LOOKUP_CONTROLLER;

  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [defaultPaymentId, setDefaultPaymentId] = useState("");
  const [merchant_email, setMerchantEmail] = useState("");
  const [merchant_id, setMerchantId] = useState("");

  const submitBusinessForm = async (e) => {
    e.preventDefault();
    
    if (loading) return;
    setLoading(true);

    if (
      !Name &&
      !PhoneNumber &&
      !Email &&
      !Description &&
      !subscriptionPlanId
    ) {
      setTimeout(() => {
        toast.error("Please, fill all required fields");
        setLoading(false);
      }, 1000);
      return;
    }
    setMerchantEmail(Email);

    if (merchant_id !== "") ProcessPayment(merchant_id);

    const res = await merchantAxiosInstance.post(
      `${submitBusinessRegForm.route}`,
      BusinessData
    );

    const { status, result, error_message } = res?.data;

    if (!status) {
      const merchantRes = await merchantAxiosInstance.get(
        `${getMerchant.route}`
      );
      console.log(merchantRes, "MY MERChANT");

      if (merchantRes?.data?.status) {
        setMerchantId(merchantRes?.data?.result[0]?.business_id);
        return;
      }
      toast.error(error_message);
      setLoading(false);
      return;
    }

    if (selectedPlan?.is_free_plan) {
      setLoading(false);
      navigate("/AfiariMerchantForm/successful");
      return;
    }

    setMerchantId(result[0]);
    return;
  };

  const ProcessPayment = async (merchantId) => {
    const options = getAvailablePaymentProcessorsLocalStorage();

    console.log(
      options,
      "payment plabs",
      defaultPaymentId,
      "default id=>",
      defaultPaymentId
    );

    const selected_option = options.find((opt) => opt?.id === defaultPaymentId);

    console.log(
      selected_option?.item_value.toLowerCase(),
      selected_option,
      "selected opion"
    );

    switch (selected_option?.item_value.toLowerCase()) {
      case "paystack":
        console.log("selected payment is paystack");

        await paystackExecutionPipeline(merchantId);
        break;

      default:
        break;
    }
  };

  const selectDefaultPaymentMethod = async () => {
    const paymentProcessorList = getAvailablePaymentProcessorsLocalStorage();
    console.log(
      paymentProcessorList,
      paymentProcessorList[0]?.id,
      "choice payment"
    );
    setDefaultPaymentId(paymentProcessorList[0]?.id);
    console.log(defaultPaymentId, "set value");
  };

  const paystackExecutionPipeline = async (merchantId) => {
    const publicKey = await getPaystackPublicKey();

    const initialize_resp = await initializeBuyPlan(merchantId);

    if (initialize_resp?.status) {
      const resp = initialize_resp.result[0];

      console.log(resp?.reference, "rep ref", merchant_email, publicKey);
      navigate(`/AfiariMerchantForm/verifyPay?access_code=${resp?.access_code}&reference=${resp?.reference}&merchantId=${merchantId}&paymentId=${defaultPaymentId}`);  

      return;
    }

    toast.error(initialize_resp?.error_message);
    return;
  };



  const getPaystackPublicKey = async () => {
    const res = await merchantAxiosInstance.get(`${getPaystackPublickey.route}`);

    const { status, result, error_message } = res?.data;

    if (!status) {
      toast.error(error_message);
      return;
    }

    return result[0];
  };

  const initializeBuyPlan = async (merchantId) => {
    const body = {
      planId: selectedPlan?.id,
      type: 1,
      merchantId: merchantId,
    };

    try {
      const res = await merchantAxiosInstance.post(
        `${buyPlan.route}?payment_method=${defaultPaymentId}`,
        body
      );
      const { status, error_message } = res.data;

      if (!status) {
        toast.error(error_message);
        setLoading(false);
        goBack();
        return;
      }

      return res.data;
    } catch (err) {
      toast.error(
        "Couldn't initialize subscription payment . Something went wrong"
      );
      console.log(err);
    }
  };

  const goBack = () => {
    setTimeout(() => {
      navigate("/AfiariMerchantForm");
    }, 3000);
  };

  const getPaymentMethod = async () => {
    const paymentProcessorsList = getAvailablePaymentProcessorsLocalStorage();

    console.log(paymentProcessorsList, "LIST OF PAYMENTS METHODS");

    if (paymentProcessorsList.length > 0) {
      selectDefaultPaymentMethod();
      return paymentProcessorsList;
    }

    const res = await merchantAxiosInstance.get(`${getPaymentOptions.route}`);

    const { status, result, error_message } = res?.data;

    if (!status) {
      toast.error(error_message);
      return;
    }

    setAvailablePaymentProcessorsToLocalStorage(result);
    selectDefaultPaymentMethod();

    return result;
  };

  const getSubPlan = async () => {
    try {
      const res = await merchantAxiosInstance.get(
        `${getSubscriptionPlan.route}`
      );
      res?.data?.status
        ? setSubscriptionPlans(res?.data?.result)
        : toast.error(res?.data?.error_message);
    } catch (err) {
      toast.error("Couldn't load subscription plans. Something went wrong");
      console.log(err);
    }
  };

  useEffect(() => {
    getSubPlan();
    getPaymentMethod();
  }, []);

  useEffect(() => {}, [defaultPaymentId]);

  useEffect(() => {
    console.log(merchant_email, merchant_id, "Hello worldhsjsd");
    if (merchant_id && merchant_id !== "") ProcessPayment(merchant_id);
  }, [merchant_email, merchant_id]);

  return (
    <FormWrapper title="Business">
      <div className="formWrapper-header">
        <h2 className="formWrapper-header_h">Register Your Business</h2>
        <p className="formWrapper-header_p">
          Please fill the required fields to register your business with us.
        </p>
      </div>
      <div className="">
        <h2>Business</h2>
        <div className="merchant_input">
          <label>Business name</label>
          <input
            type="text"
            placeholder="Enter Business name"
            required
            value={Name}
            onChange={(e) => updateFields({ Name: e.target.value })}
          />
        </div>
        <div className="merchant_input">
          <label>Email</label>
          <input
            type="email"
            placeholder="Enter Email"
            required
            value={Email}
            onChange={(e) => updateFields({ Email: e.target.value })}
          />
        </div>
        <div className="merchant_input">
          <label>Phone number</label>
          <input
            type="number"
            placeholder="Enter Phone No"
            required
            value={PhoneNumber}
            onChange={(e) => updateFields({ PhoneNumber: e.target.value })}
          />
        </div>
        <div className="merchant_input">
          <label>Description</label>
          <input
            type="text"
            placeholder="Enter a short description"
            required
            value={Description}
            onChange={(e) => updateFields({ Description: e.target.value })}
          />
        </div>
        <div className="merchant_input">
          <label>Select subscription plan</label>
          <select
            required
            onChange={(e) => {
              updateFields({ subscriptionPlanId: e.target.value });
              const selectedOption = subscriptionPlans.find(
                (option) => option.id === e.target.value
              );
              setSelectedPlan(selectedOption);
            }}
          >
            <option value="">Choose Plan</option>
            {subscriptionPlans.map((option) => (
              <option
                key={option?.id}
                value={option?.id}
                onClick={() => setSelectedPlan(option)}
              >
                {option?.plan_name} - ({option?.currency}{option?.yearly_cost_text})
              </option>
            ))}
          </select>
        </div>
        <button
          className={`merchant_form_btns_cont ${loading ? "loading" : ""}`}
          onClick={submitBusinessForm}
        >
          Submit {loading && <div className="spinner"></div>}
        </button>
      </div>
    </FormWrapper>
  );
};

export default BusinessForm;
