import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState } from "react";
import AfiariMerchant from "./pages/AfiariMerchant/AfiariMerchant";
import Preloader from "./pages/Preloader/Preloader";
import ScrollToTop from "./hooks/ScrollToTop";
import Successful from "./pages/Successful/Successful";
import AfiariAgent from "./pages/AfiariAgent/AfiariAgent";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUser } from "./tools/utils";
import { MERCHANT_PORTAL } from "./constants";
import { IDENTITY_SERVER_URL } from "./constants";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import Landing from "./pages/LandingPage/Landing";
import Blog from "./pages/Blog/blog";
import Home from "./pages/Home/home";
import Stores from "./pages/Stores/stores";
import Signin from "./pages/Preloader/signin";
import Signup from "./pages/Preloader/signup";
import VerifyPay from "./pages/Successful/verifyPay";

function App() {
  const userInfo = getUser();

  const merchant_success_msg =
    "We will be talking to you shortly to know more about your business.";
    const payMessage = "Hangon! This will only take awhile to complete.";
  const agent_success_msg = `Hello ${userInfo?.fullname} Welcome to the Afiari Family. Your request was successfully submitted, and will be attended to by our representative. Click on the buton below to go to your profile.`;
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/auth" element={<Preloader />} />
          <Route path="/AfiariMerchantForm" element={<AfiariMerchant />} />
          <Route path="/AgentNetwork" element={<AfiariAgent />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/home" element={<Home />} />
          <Route path="/stores" element={<Stores />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/signin" element={<Signin />} />
          <Route
            path="/AfiariMerchantForm/successful"
            element={
              <Successful msg={merchant_success_msg} />
            }
          />
          <Route
            path="/AfiariMerchantForm/verifypay"
            element={
              <VerifyPay msg={payMessage} />
            }
          />
          <Route
            path="/AgentNetwork/successful"
            element={
              <Successful msg={agent_success_msg} />
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
